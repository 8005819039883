import $ from 'jquery';
import Parsley from 'parsleyjs';
import api from 'general/js/api';
import pageSpinner from 'general/js/page-spinner';
import createEvent from 'general/js/create-event';
import constants from 'general/js/constants';
import eventBus from '../../../general/js/event-bus';
import HtmlHelper from '../../../general/js/html-helper';
import BaseComponent from '../../../general/js/base-component';

const ENCTYPE_URLENCODED = 'application/x-www-form-urlencoded';

const FORM_CONTROL_ATTRIBUTE = 'data-form-control';

const PARSLEY_SERVER_CONSTRAINT_NAME = 'server';
const PARSLEY_SERVER_ERROR_KEY = 'serverErrorValue';
const PARSLEY_RANGES_VALIDATOR_NAME = 'ranges';
const RESPONSE_TYPE_VALIDATION_ERRORS = 'validationErrors';
const RESPONSE_TYPE_REDIRECT = 'redirect';
const PARSLEY_AUTOCOMPLETE_STORE_VALIDATOR = 'store';
const PARSLEY_AUTOCOMPLETE_PUBLICATION_VALIDATOR = 'publication';

export const ATTRIBUTE_VALIDATE_ALWAYS = 'data-validate-always';

export default class FormComponent extends BaseComponent {
    constructor(el, id, { onServerValidationError } = {}) {
        super(el, id);

        if (this.element.nodeName !== 'FORM') {
            throw new Error('element must be a form');
        }

        this.successUrl = this.getAttribute(this.element, 'successUrl');

        if (this.isAsync() && !this.successUrl) {
            throw new Error('success url must be set');
        }

        this.method = this.element.method || 'post';
        this.action = this.element.action;
        this.enctype = this.element.enctype || ENCTYPE_URLENCODED;
        this.onServerValidationErrorHook = onServerValidationError;
        this.checkDirty = this.checkIfDirty();

        this.submitButton = this.element.querySelector('[type="submit"]');
        this.isSubmiting = false;
        this.formControlSelector = `[${FORM_CONTROL_ATTRIBUTE}]`;
        // reset form in case of success submit
        this.resetOnSuccess = ('resetOnSuccess' in this.element.dataset) ? this.element.dataset.resetOnSuccess !== 'false' : false;
        this.submitElement = this.refs.submit;

        this._parsleyForm = null;

        this.init();
    }

    init() {
        if (this.isAsync()) {
            this.getParsleyForm().on('form:submit', this.onSubmit);
        }

        this.getParsleyForm().on('field:error', this.onFieldError);

        this.checkServerErrors();

        eventBus.addListener(constants.EVENT_FORM_REVALIDATE, this.checkValidity);

        if (this.checkDirty) {
            this.element.addEventListener('change', this.checkValidity);
            this.checkValidity();
        }
    }

    checkValidity = () => {
        this.submitButton.classList.remove('is-enabled');

        this._parsleyForm.whenValid().then(() => {
            this.submitButton.classList.add('is-enabled');
        });
    };

    checkServerErrors() {
        if (this.options.serverErrors) {
            this.renderServerErrors(this.options.serverErrors);
        }
    }

    isValidationError({ data }) {
        return (typeof data === 'object') && (data.type === RESPONSE_TYPE_VALIDATION_ERRORS) && ('errors' in data);
    }

    getValidationErrors({ data }) {
        return data.errors;
    }

    getParsleyForm() {
        if (this._parsleyForm === null) {
            this._parsleyForm = new Parsley.Factory(this.element, {
                inputs: Parsley.options.inputs + ',[data-parsley-checkboxes-group]',
                excluded: (index, element) => {
                    const excludedDefault = Parsley.options.excluded;
                    const defaultExclude = element.matches(excludedDefault);
                    if (defaultExclude) {
                        return true;
                    }

                    const validateAlwaysParent = HtmlHelper.getParent(element, `[${ATTRIBUTE_VALIDATE_ALWAYS}]`);
                    return validateAlwaysParent ? false : $(element).is(':hidden');
                },
                errorClass: 'is-invalid',
                successClass: 'is-valid',
                classHandler: field => field.$element.closest(this.formControlSelector),
                errorsContainer: field => field.$element.closest(this.formControlSelector),
                errorsWrapper: '<ul class="form-control__errors"></ul>',
                errorTemplate: '<li class="form-control__error-item"></li>'
            });
        }
        return this._parsleyForm;
    }

    isAsync() {
        return this.getAttribute(this.element, 'sync') !== 'true';
    }

    checkIfDirty() {
        return this.getAttribute(this.element, 'dirty') === 'true';
    }

    onSubmit = () => {
        if (!this.isSubmiting) {
            try {
                this.beforeSubmit();
                api({
                    method: this.method,
                    url: this.getUrl(),
                    data: this.getRequestParams()
                }).then((response) => {
                    this.afterSubmit();
                    this.resetForm();
                    return this.handleSuccessSubmit(response);
                }, (error) => {
                    this.afterSubmit();
                    return this.handleErrorSubmit(error);
                });
            } catch (err) {
                console.error(err);
            }
        }

        // prevent default form submit
        return false;
    };

    handleSuccessSubmit({ data }) {
        console.log('form was sent successfully', data);

        switch (data.type) {
            case RESPONSE_TYPE_REDIRECT:
                this.handleRedirect(data.url || this.successUrl);
                break;
            default:
                throw new Error('unknown response type');
        }
    }

    handleErrorSubmit(error) {
        // first we have to proceed validation errors
        const response = error.response;
        if (this.isValidationError(response)) {
            const failedElements = [];
            this.renderServerErrors(this.getValidationErrors(response), failedElements);
            if (this.onServerValidationErrorHook) {
                this.onServerValidationErrorHook(failedElements);
            }
        }
    }

    handleRedirect(url) {
        window.location.href = url;
    }

    renderServerErrors(groupedErrors, failedElements = []) {
        let hasFieldErrors = false;

        this.getParsleyForm().fields.forEach((field) => {
            const element = field.$element[0];
            const fieldName = element.name ?
                element.name : element.getAttribute('data-dc-form-field-name');

            if (fieldName in groupedErrors) {
                hasFieldErrors = true;

                field.$element.attr(`data-parsley-${PARSLEY_SERVER_CONSTRAINT_NAME}`, 'true');

                const errors = groupedErrors[fieldName];
                delete groupedErrors[fieldName];

                // programmatically set constraint error text
                field.options.serverMessage = errors.join(', ');
                // refresh stored value
                delete field[PARSLEY_SERVER_ERROR_KEY];

                field.options.validateIfEmpty = true;

                failedElements.push(field.element);
            }
        });

        // validate to cause an error if we have field errors
        if (hasFieldErrors) {
            this.getParsleyForm().validate();
        }
    }

    getRequestParams() {
        let data = this.getFormData();
        if (this.enctype === ENCTYPE_URLENCODED) {
            data = this.getUrlencodedFormData(data);
        }
        return data;
    }

    getFormData() {
        return new FormData(this.element);
    }

    getUncheckedCheckboxesData() {
        const checkboxesInputs = [...this.element.querySelectorAll('input[type="checkbox"]')];

        function isArrayOrObject(name) {
            return /(.+)\[(.*)\]/.test(name);
        }

        return checkboxesInputs.reduce((result, checkbox) => {
            if (!checkbox.checked && !isArrayOrObject(checkbox.name)) {
                result[checkbox.name] = false;
            }
            return result;
        }, {});
    }

    getUrlencodedFormData(formData) {
        const result = [];
        const entries = formData.entries();

        // iterate over entries (IE compatible way :( )
        while (true) {
            const entry = entries.next();
            if (entry.done) break;
            const [key, value] = entry.value;
            const encodedEntry = encodeURIComponent(key) + '=' + encodeURIComponent(value == null ? '' : value);
            result.push(encodedEntry);
        }
        return result.join('&');
    }

    getUrl() {
        return this.action;
    }

    afterSubmit() {
        pageSpinner.hide();
        if (this.submitElement) {
            this.submitElement.disabled = false;
        }
        this.isSubmiting = false;
    }

    beforeSubmit() {
        this.isSubmiting = true;
        pageSpinner.show();
        if (this.submitElement) {
            this.submitElement.disabled = true;
        }
    }

    resetForm() {
        if (this.resetOnSuccess) {
            // slow down a little so modal will be able to be hidden before reset
            setTimeout(() => {
                this.element.reset();
                this.getParsleyForm().reset();
            }, 1000);
        }
    }

    onDestroy() {
        this.getParsleyForm().destroy();
    }

    onFieldError = (field) => {
        // a11y fix
        field.$element.attr('aria-describedby', field._ui.errorsWrapperId);
        // global event to notify other components
        const errorEvent = createEvent(constants.EVENT_FIELD_VALIDATION_FAILED);
        errorEvent.field = field;
        field.element.dispatchEvent(errorEvent);
    };

    static hasFieldValue(field) {
        return (field.element.tagName === 'INPUT' && (field.element.type !== 'file')) || field.element.tagName === 'TEXTAREA' || field.element.tagName === 'SELECT';
    }
}

function handledUntrackedField(field) {
    if (field[PARSLEY_SERVER_ERROR_KEY] !== true) {
        field[PARSLEY_SERVER_ERROR_KEY] = true;
        return false;
    }

    return true;
}

Parsley.addValidator(PARSLEY_SERVER_CONSTRAINT_NAME, {
    validateString: (value, c, field) => {
        // if field cant store value we show error just once
        if (!FormComponent.hasFieldValue(field)) {
            return handledUntrackedField(field);
        }

        // otherwise field is valid only if new value do not matches old one
        if (PARSLEY_SERVER_ERROR_KEY in field) {
            return field[PARSLEY_SERVER_ERROR_KEY] !== field.getValue();
        }

        field[PARSLEY_SERVER_ERROR_KEY] = field.getValue();
        return false;
    },
    validateMultiple: (values, c, field) => {
        return handledUntrackedField(field);
    },
    priority: 1024
});

Parsley.addValidator(PARSLEY_RANGES_VALIDATOR_NAME, {
    validateString: (value, c, field) => {
        const ranges = JSON.parse(field.element.getAttribute('data-ranges')) || [];

        const isValid = ranges.some((range) => {
            const [min, max] = range;
            return value >= min && value <= max;
        });

        return isValid;
    }
});

Parsley.addAsyncValidator(PARSLEY_AUTOCOMPLETE_STORE_VALIDATOR, function (xhr) {
    const stores = JSON.parse(xhr.responseText).map(store => store.name);
    const isValid = stores.some(store => store === this.$element[0].value);

    return isValid;
});


Parsley.addAsyncValidator(PARSLEY_AUTOCOMPLETE_PUBLICATION_VALIDATOR, function (xhr) {
    const publications = JSON.parse(xhr.responseText).map(pub => pub.name);
    const isValid = publications.some(pub => pub === this.$element[0].value);

    return isValid;
});
