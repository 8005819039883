import BaseComponent from '../../../general/js/base-component';
import eventBus from '../../../general/js/event-bus';
import { EVENT_FORM_SUBMIT } from '../../form/js/events';

export default class WizardAnimation extends BaseComponent {
    constructor(...args) {
        super(...args);

        this.init();
    }

    init = () => {
        setTimeout(() => {
            this.refs.container.classList.remove('show');
        }, 210);

        this.addListeners();
    };

    addListeners = () => {
        this.refs.link.addEventListener('click', this.goFadeOut);
        eventBus.addListener(EVENT_FORM_SUBMIT, this.goFadeOut);
        this.refs.submit.form.addEventListener('submit', this.goFadeOut);
    };

    goFadeOut = () => {
        this.refs.container.classList.add('hide');
    };
}
