import BaseComponent from '../../../general/js/base-component';
import Device, { DEVICE_TYPE_MOBILE_WIDE } from '../../../general/js/device';

export default class WizardHeight extends BaseComponent {
    constructor(...args) {
        super(...args);

        this.isMobile = Device.isViewportTypeLt(DEVICE_TYPE_MOBILE_WIDE);
        if (this.isMobile) {
            this.init();
        }
    }

    init = () => {
        this.calculate();
        window.addEventListener('resize', this.calculate);
    };

    calculate = () => {
        let height = window.innerHeight;
        if (height < 320) height = 320;
        this.element.style.height = (height - 70) + 'px';
    };
}
