export default class ComponentConfig {
    constructor(id, className) {
        this.id = id;
        this.className = className;
    }

    static getComponentProperty() {
        return 'data-dc-component';
    }

    static getComponentLazyProperty() {
        return 'data-dc-lazy';
    }

    static getDatasetCamelCase(attrName) {
        attrName = attrName.substr(5);
        return this.getCamelCaseSrt(attrName);
    }

    static getCamelCaseSrt(str) {
        return str.replace(/-./g, word => word.charAt(1).toUpperCase());
    }

    static getOptionsProperty(id) {
        return `data-dc-${id}-options`;
    }

    static getRefProperty(id) {
        return `data-dc-${id}-ref`;
    }

    static getComponentInstanceIdProperty() {
        return `data-dc-id`;
    }

    static getInstanceIdProperty(id) {
        return `data-dc-${id}-id`;
    }

    static getAttributeProperty(id, name) {
        return `data-dc-${id}-${name}`;
    }

    static getDatasetOptionsProperty(id) {
        return this.getDatasetCamelCase(this.getOptionsProperty(id));
    }

    static getDatasetRefProperty(id) {
        return this.getDatasetCamelCase(this.getRefProperty(id));
    }

    static insertToCollection(result, name, value) {
        // is that required? TBD
        // name = this.getCamelCaseSrt(name);
        const arrayRegexp = /(.+)\[(.*)\]/;
        const arrayParseResult = name.match(arrayRegexp);
        if (arrayParseResult !== null) {
            const name = arrayParseResult[1];
            const key = arrayParseResult[2];
            const isObject = key !== '';
            if (!(name in result)) {
                result[name] = isObject ? {} : [];
            }
            if (isObject) {
                result[name][key] = value;
            } else {
                result[name].push(value);
            }
        } else {
            result[name] = value;
        }
    }

    static getDatasetComponentInstanceIdProperty() {
        return this.getDatasetCamelCase(this.getComponentInstanceIdProperty());
    }

    static getDatasetInstanceIdProperty(id) {
        return this.getDatasetCamelCase(this.getInstanceIdProperty(id));
    }

    static getDatasetAttributeProperty(id, name) {
        return this.getDatasetCamelCase(this.getAttributeProperty(id, name));
    }
}
