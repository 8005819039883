import tippy from 'tippy.js';
import BaseComponent from '../../../general/js/base-component';
import device, { DEVICE_TYPE_TABLET } from '../../../general/js/device';
import api from '../../../general/js/api';

export default class Dropdown extends BaseComponent {
    constructor(...args) {
        super(...args);

        this.dropdown = null;
        this.button = this.refs.button;
        this.buttonText = this.refs['button-text'];
        this.eventName = this.options.event || '';
        this.isDisabled = this.options.disabled;

        this.init();
    }

    init() {
        this.dropdown = tippy(this.button, {
            delay: 50,
            arrow: !!this.isMobile,
            duration: this.isMobile ? 200 : 0,
            animation: this.isMobile ? 'scale' : 'fade',
            size: 'small',
            placement: this.isMobile ? 'bottom' : 'bottom-start',
            maxWidth: this.isMobile ? '220px' : 'none',
            flip: false,
            interactive: true,
            theme: this.isMobile ? 'light-border' : 'light-border dropdown',
            distance: this.isMobile ? 10 : -1,
            trigger: 'click',
            onShow: this._onShow.bind(this),
            onHide: this._onHide.bind(this),
            onMount: this._onMount.bind(this),
            onShown: this._onShown.bind(this)
        });

        this._handleDevice();
    }

    _handleDevice() {
        this._onDeviceTypeChanged();
        device.subscribeOnDeviceTypeChange(this._onDeviceTypeChanged);
    }

    _onDeviceTypeChanged = () => {
        this.isMobile = device.isViewportTypeLt(DEVICE_TYPE_TABLET);

        if (this.isMobile) {
            this._setMobileMode();
            this.button.removeAttribute('disabled');
            return;
        }

        this._setNonMobileMode();

        if (this.isDisabled || (this.dropdown && this.dropdown.props.content.trim() === '')) {
            this.button.setAttribute('disabled', 'disabled');
        }
    };

    addListeners(triggers) {
        if (!triggers || this.mounted) {
            return;
        }

        this.mounted = true;
        triggers.forEach((item) => {
            item.addEventListener('change', this._onTriggerChange);
        });
    }

    removeListeners(triggers) {
        if (!triggers || !this.mounted) {
            return;
        }

        this.mounted = false;
        triggers.forEach((item) => {
            item.removeEventListener('change', this._onTriggerChange);
        });
    }

    _onTriggerChange = (e) => {
        const label = e.target.nextElementSibling;
        const labelText = label.querySelector('[data-label-content]').textContent;
        this.buttonText.textContent = labelText;

        const passthrough = label.querySelector('[data-passthrough]');
        if (passthrough) {
            const attribute = passthrough.attributes.getNamedItem('data-passthrough');
            if (attribute) {
                this.buttonText.setAttribute('data-passthrough', attribute.value);
            }
        }

        this._sendForm(e.target);
        this.dropdown.hide(0);
    };

    _sendForm = (target) => {
        const form = target.form;

        if (form) {
            api.post(form.action, new FormData(form)).catch((error) => {
                console.log(error);
            });
        }
    };

    _onMount() {
        const dropdownContent = document.querySelector(`#tippy-${this.dropdown.id}`);
        this.triggers = [...dropdownContent.querySelectorAll('[data-dropdown-input]')];
        this.addListeners(this.triggers);
    }

    _onShow() {
        if (this.dropdown.props.content.trim() === '') {
            return false;
        }

        if (!this.isMobile) {
            this.element.classList.add('is-open');
        }
    }

    _onShown() {
        if (this.isMobile) {
            return;
        }

        const width = this.element.getBoundingClientRect().width;
        document.querySelector(`#tippy-${this.dropdown.id}`).style.width = `${width}px`;
    }

    _setMobileMode() {
        if (!this.dropdown) {
            return;
        }

        this.dropdown.set({
            arrow: true,
            duration: 200,
            maxWidth: '220px',
            animation: 'scale',
            placement: 'bottom',
            theme: 'light-border',
            distance: 10,
            content: this.refs['mobile-content'].innerHTML
        });
    }

    _setNonMobileMode() {
        if (!this.dropdown) {
            return;
        }

        this.dropdown.hide(0);

        this.dropdown.set({
            arrow: false,
            duration: 0,
            maxWidth: 'none',
            animation: 'fade',
            placement: 'bottom-start',
            theme: 'light-border dropdown',
            distance: -1,
            content: this.refs['desktop-content'].innerHTML
        });
    }

    _onHide() {
        this.element.classList.remove('is-open');
        this.removeListeners(this.triggers);
    }
}
