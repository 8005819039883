import BaseComponent from '../../../general/js/base-component';

export default class ToggleArea extends BaseComponent {
    constructor(...args) {
        super(...args);

        this.isOpen = false;
        this.btn = this.refs.button;
        this.area = this.refs.area;
        this.init();
    }

    init() {
        this.btn.addEventListener('click', this.onOpenBtnClick.bind(this));
    }

    onOpenBtnClick() {
        if (!this.isOpen) {
            this.open();
        } else {
            this.close();
        }
    }

    open = () => {
        this.isOpen = true;

        this.changeClass(this.btn, 'add', 'is-open');
        this.changeClass(this.area, 'add', 'go-show');
        this.changeClass(this.area, 'remove', 'disabled');

        setTimeout(() => {
            this.changeClass(this.area, 'remove', 'go-show');
        }, 310);
    };

    close = () => {
        this.isOpen = false;

        this.changeClass(this.btn, 'remove', 'is-open');
        this.changeClass(this.area, 'add', 'go-hide');

        setTimeout(() => {
            this.changeClass(this.area, 'add', 'disabled');
            this.changeClass(this.area, 'remove', 'go-hide');
        }, 310);
    };

    changeClass = (target, action, cssClass) => {
        requestAnimationFrame(() => target.classList[action](cssClass));
    };
}
