var Handlebars = require("c:\\Websites\\AdBuilderGUI\\Latest\\Web\\node_modules\\handlebars\\runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isDisabled : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <div class=\"col-mobile-wide-6 col-desktop-4 col-desktop-wide-3\" data-grid-item data-anime-item>\r\n            <div class=\"card card--type-image\" data-form-control data-tooltip-parent>\r\n                <input class=\"card__control\"\r\n                       type=\"radio\" name=\"SelectedCampaignId\"\r\n                       "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isSelected : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n                       value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"\r\n                       id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"\r\n                       disabled>\r\n                <label class=\"card__label\" for=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\r\n                    <div class=\"card__media\">\r\n                        <img class=\"card__image\" src=\""
    + alias4(((helper = (helper = helpers.thumbnailFilePath || (depth0 != null ? depth0.thumbnailFilePath : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnailFilePath","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.code || (depth0 != null ? depth0.code : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code","hash":{},"data":data}) : helper)))
    + "\">\r\n                        <svg class=\"icon\" width=\"100\" height=\"75\" focusable=\"false\">\r\n                            <use xlink:href=\"#icon-placeholder\"></use>\r\n                        </svg>\r\n                        <div class=\"card__tags card__tags--bottom-right\">\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.groups : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </div>\r\n                    </div>\r\n                    <div class=\"card__content\">\r\n                        <div class=\"card__title\">\r\n                            "
    + alias4(((helper = (helper = helpers.code || (depth0 != null ? depth0.code : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code","hash":{},"data":data}) : helper)))
    + "\r\n                        </div>\r\n                    </div>\r\n                    <div class=\"card__switch\">\r\n                        <button type=\"button\" class=\"btn-frameless btn-frameless--red\" data-dc-component=\"tooltip\" data-dc-tooltip-options='{\"maxWidth\": \"170px\", \"placement\" : \"left-start\"}' aria-label=\"toggle tooltip\" data-tooltip-content=\""
    + alias4(((helper = (helper = helpers.errorMessageForStep || (depth0 != null ? depth0.errorMessageForStep : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"errorMessageForStep","hash":{},"data":data}) : helper)))
    + "\">\r\n                        <svg class=\"icon\" width=\"27\" height=\"27\" focusable=\"false\">\r\n                            <use xlink:href=\"#icon-exclamation\"></use>\r\n                        </svg>\r\n                    </div>\r\n            </div>\r\n        </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " checked";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                                <div class=\"tag\" data-tag-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <div class=\"col-mobile-wide-6 col-desktop-4 col-desktop-wide-3\" data-grid-item data-anime-item>\r\n            <div class=\"card card--type-image"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isCustomizable : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-form-control data-dc-component=\"card\">\r\n                <input data-immediate-action\r\n                       required\r\n                       data-parsley-errors-container=\"#global_errors\"\r\n                       data-parsley-required-message=\"Please choose one\"\r\n                       data-dc-wizard-animation-ref=\"campaigns[]\"\r\n                       data-dc-wizard-steps-ref=\"inputs[]\"\r\n                       data-dc-label=\""
    + alias4(((helper = (helper = helpers.code || (depth0 != null ? depth0.code : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code","hash":{},"data":data}) : helper)))
    + "\"\r\n                       class=\"card__control\"\r\n                       type=\"radio\"\r\n                       name=\"SelectedCampaignId\"\r\n                       "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isSelected : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\r\n                       value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"\r\n                       id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"\r\n                       data-parsley-multiple=\"SelectedCampaignId\">\r\n                <label class=\"card__label\" for=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\r\n                    <div class=\"card__media\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isCustomizable : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        <img class=\"card__image\" src=\""
    + alias4(((helper = (helper = helpers.thumbnailFilePath || (depth0 != null ? depth0.thumbnailFilePath : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnailFilePath","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.code || (depth0 != null ? depth0.code : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code","hash":{},"data":data}) : helper)))
    + "\">\r\n                        <svg class=\"icon\" width=\"100\" height=\"75\" focusable=\"false\">\r\n                            <use xlink:href=\"#icon-placeholder\"></use>\r\n                        </svg>\r\n                        <div class=\"card__tags card__tags--bottom-right\">\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.groups : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </div>\r\n                    </div>\r\n                    <div class=\"card__content\">\r\n                        <div class=\"card__title\">\r\n                            "
    + alias4(((helper = (helper = helpers.code || (depth0 != null ? depth0.code : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code","hash":{},"data":data}) : helper)))
    + "\r\n                        </div>\r\n                    </div>\r\n                    <div class=\"card__switch\">\r\n                        <svg class=\"icon\" width=\"21\" height=\"21\" focusable=\"false\">\r\n                            <use xlink:href=\"#icon-checked\"></use>\r\n                        </svg>\r\n                    </div>\r\n                </label>\r\n            </div>\r\n        </div>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    return " card--customizable";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            <div class=\"card__media-customizable\" data-dc-card-ref=\"customizable\">\r\n                                <div class=\"card__media-customizable-background\"></div>\r\n\r\n                                <button class=\"card__media-customizable-button\" type=\"button\" data-dc-card-ref=\"customizableButton\">\r\n                                </button>\r\n\r\n                                <svg class=\"icon card__media-customizable-icon\" width=\"16\" height=\"16\" focusable=\"false\">\r\n                                    <use xlink:href=\"#icon-pencil\"></use>\r\n                                </svg>\r\n\r\n                                <div style=\"display: none\" data-dc-card-ref=\"tooltipContent\">\r\n                                    <div class=\"card__media-customizable-tooltip-content\">\r\n                                        <h4 class=\"card__media-customizable-tooltip-title\">Customizable</h4>\r\n                                        <ul class=\"card__media-customizable-tooltip-list\">\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.contentItems : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                                        </ul>\r\n                                    </div>\r\n                                </div>\r\n                            </div>\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                                                <li class=\"card__media-customizable-tooltip-item\">- "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</li>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});