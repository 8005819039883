import BaseComponent from '../../../general/js/base-component';
import eventBus from '../../../general/js/event-bus';
import { EVENT_INPUT_FILLED, EVENT_INPUT_CLEANED } from './events';

export default class FormInput extends BaseComponent {
    constructor(...args) {
        super(...args);
        this.init();
    }

    init = () => {
        this.inputNode = this.element.querySelector('input');

        if (!this.inputNode) return;

        this.checkFilling();
        this.addListeners();
    };

    addListeners = () => {
        this.inputNode.addEventListener('focus', this.onFocusInput);
        this.inputNode.addEventListener('blur', this.onBlurInput);
        this.inputNode.addEventListener('animationstart', this.onAnimationStart);
        this.inputNode.addEventListener('input', this.checkFilling);
    };

    onFocusInput = () => {
        this.element.classList.add('is-focused');
        this.checkFilling();
    };

    onBlurInput = () => {
        this.element.classList.remove('is-focused');
        this.checkFilling();
    };

    checkFilling = () => {
        if (this.inputNode.value) {
            this.element.classList.add('is-filled');
            eventBus.emit(EVENT_INPUT_FILLED, this.element);
        } else {
            eventBus.emit(EVENT_INPUT_CLEANED);
            this.element.classList.remove('is-filled');
        }
    };

    onAutoFillStart = () => {
        this.element.classList.add('is-filled');
    };

    onAutoFillCancel = () => {
        this.element.classList.remove('is-filled');
    };

    onAnimationStart = ({ target, animationName }) => {
        if (this.inputNode.value) return;
        switch (animationName) {
            case 'onAutoFillStart':
                return this.onAutoFillStart(target);
            case 'onAutoFillCancel':
                return this.onAutoFillCancel(target);
            default:
            // do nothing
        }
    };
}
