import FormRange from './form-range.component';
import BaseComponent from '../../../general/js/base-component';

export default class FormRangeManager extends BaseComponent {
    constructor(...args) {
        super(...args);
        this.instances = [];
        this.ranges = this.options.ranges;
        this.rangesNodes = this.refs.formRanges;
        this._init();
    }

    _init() {
        this._createRangeComponents();
        this._renderBufferForRanges();
    }

    _createRangeComponents() {
        this.rangesNodes.forEach((element) => {
            const instance = new FormRange(element, 'form-range', {
                isDelegatedControl: true,
                onChangeCb: this._renderBufferForRanges
            });
            instance.init();
            this.instances.push(instance);
        });
    }

    _getIndexesWithinRange(val, arr) {
        const indexes = [];

        arr.forEach((subArr, index) => {
            const [a, b] = subArr;
            if (this._isBetween(val, a, b)) {
                indexes.push(index);
            }
        });

        return indexes;
    }

    _isBetween(value, min, max) {
        return value >= min && value <= max;
    }

    _renderBufferForRanges = () => {
        for (let i = 0; i < this.rangesNodes.length; i++) {
            const value = this.instances[i].getValue();
            const indexes = this._getIndexesWithinRange(value, this.ranges[i]);
            for (let j = 0; j < this.rangesNodes.length; j++) {
                if (i !== j) {
                    const ranges = indexes.map(idx => this.ranges[j][idx]);
                    this.instances[j].update(ranges);
                }
            }
        }
    };

    destroy() {
        this.instances.forEach((instance) => {
            instance.destroy();
        });
        this.instances = [];
    }
}
