import BaseComponent from '../../../general/js/base-component';

export default class FormInputCharactersManager extends BaseComponent {
    constructor(...args) {
        super(...args);
        this.init();
    }

    init() {
        this.refs.charactersCount.innerHTML = this.options.maxNumberCharacters;

        this.addListener(this.refs.input, 'input', this.manageCharacters);
    }

    manageCharacters = (event) => {
        const input = event.target;
        const remainingCharacters = this.options.maxNumberCharacters - event.target.value.length;

        if (remainingCharacters >= 0) {
            this.refs.charactersCount.innerHTML = remainingCharacters;
        }

        if (remainingCharacters === 0) {
            this.refs.message.classList.add('is-exceeded');
        } else {
            this.refs.message.classList.remove('is-exceeded');
        }
    }
}
