import noUiSlider from 'nouislider';
import BaseComponent from '../../../general/js/base-component';
import createEvent from '../../../general/js/create-event';
import eventBus from '../../../general/js/event-bus';
import constants from '../../../general/js/constants';

const NOUI_BUFFER_CLASSNAME = 'noUi-buffer';

export default class FormRange extends BaseComponent {
    constructor(element, id, extendedOptions = {}) {
        super(element, id);
        this.options = { ...this.options, ...extendedOptions };
        this.noUiBaseNodeEl = null;
        this.rangeElement = this.refs.range;
        this.inputElement = this.refs.input;
        this.oldInputValue = this.options.start || 0;
        this.rangeSlider = null;
        this.start = this.options.start || 50;
        this.min = this.options.min || 0;
        this.max = this.options.max || 100;
        this.step = this.options.step || 1;
        this.bufferEl = null;
        this.bufferSize = 0;
        this.value = null;
    }

    init() {
        this._initRange();
        this.addListeners();
    }

    addListeners() {
        this.addListener(this.inputElement, 'change', this._onInputChange);
        this.addListener(this.inputElement, 'keypress', this._onInputKeyPress);
        this.rangeSlider.on('update', this._onRangeUpdate);
        this.rangeSlider.on('change', this._onRangeChange);
        this.rangeSlider.on('set', this._onRangeChange);
    }

    getValue() {
        return this.value !== null ? this.value : this.start;
    }

    _onRangeUpdate = (values, handle) => {
        this.inputElement.value = parseInt(values[handle], 10).toFixed();
        this.oldInputValue = this.inputElement.value;
    };

    _onRangeChange = (values, handle) => {
        const value = parseInt(values[handle], 10).toFixed();

        this.value = value;

        if (this.options.isDelegatedControl) {
            this.options.onChangeCb(this);
        }

        if (this.oldInputValue !== value) {
            const event = createEvent('input');
            this.inputElement.dispatchEvent(event);
        }
    };

    _setBufferStyles(size) {
        Object.keys(size).forEach((key) => {
            this.bufferEl.style[key] = size[key];
        });
    }

    update(ranges) {
        const noUiBufferNodes = [...this.noUiBaseNodeEl.querySelectorAll(`.${NOUI_BUFFER_CLASSNAME}`)];

        noUiBufferNodes.forEach((node) => {
            this.noUiBaseNodeEl.removeChild(node);
        });

        const mergedRanges = this._mergeRanges(ranges);

        mergedRanges.forEach((range) => {
            const bufferSize = this._calculateBufferSize(range);
            this._renderBufferNode(bufferSize);
        });

        this.inputElement.setAttribute('data-ranges', JSON.stringify(mergedRanges));

        this._dispatchEvents();
    }

    _dispatchEvents() {
        const event = createEvent('input');
        this.inputElement.dispatchEvent(event);
        eventBus.emit(constants.EVENT_FORM_REVALIDATE);
    }

    _renderBufferNode(size) {
        this.bufferEl = document.createElement('div');
        this.bufferEl.classList.add(NOUI_BUFFER_CLASSNAME);
        this._setBufferStyles(size);
        this.noUiBaseNodeEl.appendChild(this.bufferEl);
    }

    _mergeRanges(ranges) {
        return ranges.reduce((combined, next) => {
            if (combined.length && next[0] < combined[combined.length - 1][1]) {
                const prev = combined.pop();
                combined.push([prev[0], Math.max(prev[1], next[1])]);
            } else if (!combined.length || next[0] - combined[combined.length - 1][1] !== 1) {
                combined.push(next);
            } else {
                const prev = combined.pop();
                combined.push([prev[0], Math.max(prev[1], next[1])]);
            }
            return combined;
        }, []);
    }

    _calculateBufferSize(range) {
        const [min, max] = range;
        const leftPos = ((min - this.min) * 100) / (this.max - this.min);
        const widthValue = ((max - min) * 100) / (this.max - this.min);

        return {
            left: `${leftPos}%`,
            width: `${widthValue}%`
        };
    }

    _onInputChange = (e) => {
        this.rangeSlider.set(e.target.value);
    };

    _onInputKeyPress = (e) => {
        const key = e.which || e.keyCode;

        if (key === 13 && this.oldInputValue !== e.target.value) {
            e.preventDefault();
            this.oldInputValue = e.target.value;
            this.rangeSlider.set(e.target.value);
        }
    };

    _initRange() {
        this.rangeSlider = noUiSlider.create(this.rangeElement, {
            start: this.start,
            connect: false,
            step: this.step,
            range: {
                min: this.min,
                max: this.max
            }
        });
        this.noUiBaseNodeEl = this.rangeElement.querySelector('.noUi-base');
    }

    onDestroy() {
        this.rangeSlider.off();
        this.rangeSlider.destroy();
    }
}
