import FontFaceObserver from 'fontfaceobserver';

// global fonts loading error handler
function reject(error) {
    // eslint-disable-next-line no-console
    console.error('Fond loading error:', error);
    return Promise.reject(error);
}

const fonts = [];

const specsaversWebRegular = new FontFaceObserver('Specsavers Web', {
    weight: 400
});

const specsaversWebMedium = new FontFaceObserver('Specsavers Web', {
    weight: 500
});

const specsaversWebBold = new FontFaceObserver('Specsavers Web', {
    weight: 700
});

const timeout = 4000;

fonts.push(specsaversWebRegular.load(null, timeout));
fonts.push(specsaversWebMedium.load(null, timeout));
fonts.push(specsaversWebBold.load(null, timeout));

const fontsPromise = Promise.all(fonts).then(
    () => {
        if (!sessionStorage.isFontsLoaded) {
            document.documentElement.className += ' fonts-loaded';
            sessionStorage.isFontsLoaded = true;
        }
    },
    error => reject(error)
);

// by chaining from this promise we are able to catch font loading error
export { fontsPromise };

// by chaining from this promise we can be sure all fonts process are finished
const fontsFinally = fontsPromise.catch(() => {
    // we do not want to handle just wrap
});

export default fontsFinally;
