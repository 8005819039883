import BaseComponent from 'general/js/base-component';
import gtm from 'general/js/gtm';

const WIZARD_CHOOSE_CAMPAING = 'Select Campaign';

export default class WizardSteps extends BaseComponent {
    constructor(...args) {
        super(...args);

        this.step = window.appState.step;
        this.documentName = window.appState.documentName;
        this.adBuildTimeStamp = window.appState.adBuildTimeStamp;
        this.templateName = window.appState.templateName;

        this.inputs = this.refs.inputs || [];
        this.onSubmit = this.onSubmit.bind(this);
        this.onClickDownloadButton = this.onClickDownloadButton.bind(this);

        this.init();
    }

    init() {
        if (this.refs.wizardForm) {
            this.refs.wizardForm.addEventListener('submit', this.onSubmit);
        }

        if (Array.isArray(this.refs.downloadButtons)) {
            this.refs.downloadButtons.forEach(button => {
                button.addEventListener('click', this.onClickDownloadButton);
            });
        }

        if (window.appState.isLogin === 'true') {
            gtm.sendEvent('Login', 'Sumbit', 'Success', window.appState.userId);
        }


        if (this.templateName.length) {
            gtm.sendEvent('Templates', 'Select', this.templateName, 0);
        }

        if (this.adBuildTimeStamp.length) {
            gtm.sendEvent('Ads Sent', 'Submit', this.adBuildTimeStamp, 0);
        }
    }

    onClickDownloadButton(event) {
        const isError = event.currentTarget.classList.contains('error');
        if (!isError) {
            gtm.sendEvent('Download', 'Select', document.title, event.currentTarget.href);
        }
    }

    onSubmit() {
        if (this.step === WIZARD_CHOOSE_CAMPAING) {
            const selectedCampaign = this.getSelectedItem();

            if (selectedCampaign) {
                gtm.sendEvent('Campaign', 'Select', this.getLabel(selectedCampaign), 0);
            }
        }

        if (window.location.pathname.match('wizard') && this.step !== WIZARD_CHOOSE_CAMPAING) {
            gtm.sendEvent('Wizard step', 'Sumbit', window.appState.step, window.appState.userId);
        }
    }

    getSelectedItem() {
        return this.inputs.find(input => input.checked === true);
    }

    getLabel(element) {
        return element.getAttribute('data-dc-label');
    }
}
