class PageSpinner {
    constructor() {
        this.isVisible = false;
        this.isInited = false;
        this.spinnerElement = null;
    }

    init() {
        this.spinnerElement = document.createElement('div');
        this.spinnerElement.className = 'page-spinner';
        document.body.insertAdjacentElement('beforeend', this.spinnerElement);
        this.isInited = true;
    }

    show() {
        if (!this.isInited) {
            this.init();
        }
        if (!this.isVisible) {
            this.spinnerElement.classList.add('is-visible');
            this.isVisible = true;
        }
    }

    hide() {
        if (this.isVisible) {
            this.spinnerElement.classList.remove('is-visible');
            this.isVisible = false;
        }
    }
}

const instance = new PageSpinner();
export default instance;
