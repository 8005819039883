import BaseComponent from '../../../general/js/base-component';

const PREFIX_WRAPPER = 'dropdown-wrapper-';
const PREFIX_SELECT = 'dropdown-select-';

const DISPLAY_STYLE_VISIBLE = 'block';
const DISPLAY_STYLE_HIDDEN = 'none';

/** Explanation of how I work can be found in the README.md */
export default class AppearingDropdowns extends BaseComponent {
    constructor(...args) {
        super(...args);

        this.init();
    }

    init() {
        const { optionalValueLabel } = this.options;

        const dropdowns = this.getDropdowns();
        const optionalDropdowns = dropdowns.filter(
            dropdown => dropdown.select$.options[0].label === optionalValueLabel
        );

        this.hideDropdownsExceptFirst(optionalDropdowns);
        this.addEventsForDropdownsToAppearAfterChange(optionalDropdowns);
    }

    getDropdowns() {
        const dropdownTable = {};

        Object.keys(this.refs).forEach((key) => {
            let name = '';
            let select$ = null;
            let selectWrapper$ = null;

            if (key.startsWith(PREFIX_SELECT)) {
                name = key.replace(PREFIX_SELECT, '');
                select$ = this.refs[key];
            }

            if (key.startsWith(PREFIX_WRAPPER)) {
                name = key.replace(PREFIX_WRAPPER, '');
                selectWrapper$ = this.refs[key];
            }

            if (!name) {
                console.error(`Unknown data attribute: ${key}`);
                return;
            }

            const entry = dropdownTable[name];

            if (!entry) {
                dropdownTable[name] = {
                    select$,
                    selectWrapper$,
                };
                return;
            }

            dropdownTable[name] = {
                select$: entry.select$ || select$,
                selectWrapper$: entry.selectWrapper$ || selectWrapper$,
            };
        });

        return Object.values(dropdownTable);
    }

    hideDropdownsExceptFirst(dropdowns) {
        dropdowns.forEach((dropdown, index) => {
            if (index === 0) {
                return;
            }

            dropdown.selectWrapper$.style.display = DISPLAY_STYLE_HIDDEN;
        });
    }

    addEventsForDropdownsToAppearAfterChange(dropdowns) {
        dropdowns.forEach((visibleDropdown, index) => {
            const hiddenDropdown = dropdowns[index + 1];
            if (!hiddenDropdown) {
                return;
            }

            visibleDropdown.select$.addEventListener(
                'change',
                () => { hiddenDropdown.selectWrapper$.style.display = DISPLAY_STYLE_VISIBLE; },
                { once: true }
            );
        });
    }
}
