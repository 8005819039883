import anime from 'animejs';
import constants from '../../../general/js/constants';
import api from '../../../general/js/api';
import ElementSpinner from '../../../general/js/element-spinner';
import CancelingProxy from '../../../general/js/api/canceling-proxy';
import template from '../html/list.hbs';
import BaseComponent from '../../../general/js/base-component';
import eventBus from '../../../general/js/event-bus';
import { EVENT_CUSTOM_SELECT_CHANGE } from '../../form/js/events';
import ComponentsFactory from '../../../general/js/components-factory';

export default class ListWithFilter extends BaseComponent {
    constructor(...args) {
        super(...args);

        this.filter = this.refs.filter;
        this.list = this.refs.list;
        this.grid = this.refs.grid;
        this.currentPage = 0;
        this.hasMorePages = true;
        this.spinner = null;
        this.endPointUrl = this.options.endPointUrl;
        this.subcategoryId = this.options.subcategoryId;
        this.businessStreamId = this.options.businessStreamId;
        this.marketId = this.options.marketId;
        this.regionId = this.options.regionId;
        this.pageSize = this.options.pageSize;
        this.loadingTime = 300;
        this.isLoading = false;
        this.triggerPoint = 100;
        this.api = new CancelingProxy(api);

        this.init();
    }

    init() {
        console.log('ListWithFilter is running!');
        this.addListeners();
        this.bootstrap();
    }

    bootstrap() {
        this.spinner = new ElementSpinner(this.element);
    }

    addListeners() {
        this.filter.addEventListener('change', this.onFilterChange);
        this.filter.addEventListener('change', this.onFilterChange);
        this.list.addEventListener('scroll', this.onListScroll);
        eventBus.addListener(EVENT_CUSTOM_SELECT_CHANGE, this.onFilterChange);
    }

    onFilterChange = (e) => {
        const target = e.target;
        const filterValue = target.options[target.selectedIndex].value;
        this.reset();
        this.getNextPage(filterValue, 'replace', 0);
    };

    reset() {
        this.currentPage = 0;
        this.hasMorePages = true;
    }

    getFilterValue() {
        return +(this.filter.value);
    }

    onListScroll = (e) => {
        const list = e.target;

        if (
            !this.isLoading &&
            list.scrollTop + list.clientHeight + this.triggerPoint > list.scrollHeight
        ) {
            this.getNextPage();
        }
    };

    incrementPage() {
        this.currentPage++;
    }

    getNextPage(
        filterValue = this.getFilterValue(),
        method = 'append',
        page = this.currentPage + 1
    ) {
        if (!this.hasMorePages) return;

        this.isLoading = true;
        this.showSpinner();

        const url = `${this.endPointUrl}/${this.marketId}/${this.regionId}/${this.subcategoryId}/${
            this.businessStreamId
        }/${page}${(!isNaN(filterValue) ? ('/' + filterValue) : '')}?pageSize=${this.pageSize}`;

        setTimeout(() => {
            api.get(url).then(
                (response) => {
                    this.data = response.data;
                    this.render(method);
                    this.checkPages();
                    this.hideSpinner();
                    this.isLoading = false;
                },
                (error) => {
                    this.hideSpinner();
                    this.isLoading = false;
                    console.log(error);
                }
            );
        }, this.loadingTime);
    }

    render(method) {
        this.model = [...this.data];
        const html = template(this.model);

        const temp = document.createElement('div');

        temp.innerHTML = html;

        switch (method) {
            case 'replace':
                this.items = this.grid.querySelectorAll('[data-grid-item]');
                this.playOut(this.items).play();
                this.playOut(this.items).finished.then(() => {
                    ComponentsFactory.getInstance().destroy(this.list);
                    this.list.scrollTop = 0;
                    this.grid.innerHTML = temp.innerHTML;
                    ComponentsFactory.getInstance().init(this.list);
                    this._afterRender();
                });
                break;
            case 'append':
                this.grid.insertAdjacentHTML('beforeend', temp.innerHTML);
                ComponentsFactory.getInstance().init(this.list);
                this.incrementPage();
                this._afterRender();
                break;
            default:
                break;
        }
    }

    _afterRender() {
        this.playIn();
        eventBus.emit(constants.EVENT_FORM_REVALIDATE);
    }

    playOut = items =>
        anime
            .timeline({
                easing: 'easeOutCubic',
                duration: 250
            })
            .add({
                targets: items,
                opacity: 0,
                delay: anime.stagger(15),
                duration: 250
            });

    playIn() {
        this.items = [...this.grid.querySelectorAll('[data-anime-item]:not(.animated)')];

        anime({
            targets: this.items,
            opacity: [0, 1],
            delay: anime.stagger(50),
            duration: 300,
            easing: 'easeOutCubic',
            complete: (anim) => {
                this.items.forEach((element) => {
                    element.classList.add('animated');
                });
            }
        });
    }

    checkPages() {
        this.hasMorePages = !(this.model.length < this.pageSize);
    }

    showSpinner = () => {
        this.spinner.show();
    };

    hideSpinner = () => {
        this.spinner.hide();
    };
}
