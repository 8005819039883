import BaseComponent from '../../../general/js/base-component';
import eventBus from '../../../general/js/event-bus';
import { EVENT_CUSTOM_SELECT_CHANGE } from './events';

export default class CustomSelect extends BaseComponent {
    constructor(...args) {
        super(...args);

        this.clon = this.refs.clon;
        this.clonList = this.refs.clonList;
        this.original = this.refs.original;
        this.container = this.refs.container;
        this.arrow = this.refs.arrow;
        this.init();
    }

    init = () => {
        this.reworkClon();

        this.clon.addEventListener('click', this.toggleList);
        this.clonList.addEventListener('click', this.onChoose);
    };

    toggleList = () => {
        if (!this.opened) {
            this.showList();
        } else {
            this.hideList();
        }
    };

    onChoose = (e) => {
        if (e.target !== e.currentTarget) {
            const target = e.target;

            if (this.lastActive) {
                this.lastActive.classList.remove('active');
            }

            this.clon.innerHTML = target.innerHTML;
            this.original[target.number].selected = true;
            target.classList.add('active');
            this.lastActive = target;
            this.hideList();
            const fakeEvent = {
                target: this.original
            };
            eventBus.emit(EVENT_CUSTOM_SELECT_CHANGE, fakeEvent);
        }
    };


    showList = () => {
        if (!this.opened && !this.isAnimate) {
            this.isAnimate = true;
            this.opened = true;
            this.container.classList.add('show');
            this.element.classList.add('opened');

            for (let i = 0; i < this.customOptions.length; i++) {
                this.customOptions[i].setAttribute('tabindex', '0');
                this.customOptions[i].setAttribute('aria-hidden', 'false');
            }

            setTimeout(() => {
                this.isAnimate = false;

                requestAnimationFrame(() => {
                    document.addEventListener('click', this.checkTarget);
                    document.addEventListener('focusin', this.checkTarget, true);
                });
            }, 310)
        }
    };

    checkTarget = (e) => {
        if (!this.clonList.contains(e.target)) {
            this.hideList();
        }
    };


    hideList = () => {
        if (this.opened && !this.isAnimate) {
            this.isAnimate = true;
            this.opened = false;
            this.container.classList.remove('show');
            this.element.classList.remove('opened');

            for (let i = 0; i < this.customOptions.length; i++) {
                this.customOptions[i].setAttribute('tabindex', '-1');
                this.customOptions[i].setAttribute('aria-hidden', 'true');
            }

            document.removeEventListener('click', this.checkTarget);
            document.removeEventListener('focusin', this.checkTarget, true);

            setTimeout(() => {
                this.isAnimate = false;
            }, 310)
        }
    };
    
    reworkClon = () => {
        this.containerHeight = parseInt(window.getComputedStyle(this.original).height);
        const className = 'form-select__custom-item';
        const selected = this.original.selectedIndex;
        this.clon.innerHTML = this.original[selected].text;
        this.customOptions = [];

        for (let i = 0; i < this.original.length; i++) {
            const itemButton = document.createElement('button');
            itemButton.className = className;
            itemButton.setAttribute('tabindex', '-1');
            itemButton.setAttribute('aria-hidden', 'true');
            itemButton.setAttribute('type', 'button');
            itemButton.innerHTML =  this.original[i].text;
            itemButton.value = this.original[i].value;
            itemButton.number = i;
            this.clonList.appendChild(itemButton);
            this.customOptions.push(itemButton);
            if (this.original.selectedIndex === i) {
                itemButton.classList.add('active');
                this.lastActive = itemButton;
            }
            if (i === 0) this.firstElement = itemButton;
        }
    };
}
