import Awesomplete from 'awesomplete';
import api from '../../../general/js/api';
import ElementSpinner from '../../../general/js/element-spinner';
import BaseComponent from '../../../general/js/base-component';
import eventBus from '../../../general/js/event-bus';
import { EVENT_REMOVED_REPLICATOR_ITEM } from '../../replicator/js/events';
import {
    EVENT_AUTOCOMPLETE_SELECTED,
    EVENT_AUTOCOMPLETE_CHANGED,
    EVENT_AUTOCOMPLETE_SHOWED,
    EVENT_AUTOCOMPLETE_BLURED
} from './events';
import { handler } from '../../../general/js/select-handler';

export default class AutoComplete extends BaseComponent {
    constructor(...args) {
        super(...args);
        this.init();
    }

    setVariables = () => {
        this.selectedList = [];
        this.lastSelection = null;
        this.isInited = true;
        this.inputNode = this.refs.input;
        this.hiddenInputNode = this.refs['hidden-input'];
        this.autoCompleteInstanse = null;
        this.autoCompleteEnabled = this.element.getAttribute('data-autocomplete-enabled') ?
            this.element.getAttribute('data-autocomplete-enabled') === 'true' :
            true;

        this.jsonData = null;

        this.isValueSelected = false;
        this.endPoint = this.element.getAttribute('data-endpoint');
        this.minChairs = 2;
        this.maxItems = 100;
        this.onKeyUpTimeOut = null;
        this.loadingTime = 300;
        this.spinner = null;

        this.settings = { ...this.defaultSettings, ...this.element.dataset };
    };

    addListeners = () => {
        this.addListener(this.inputNode, 'input', e => this.handleInputFill(e));
        this.addListener(this.inputNode, 'focus', e => this.handleInputFocus(e));
        this.addListener(this.inputNode, 'blur', e => this.handleInputBlur(e));
        this.addListener(this.inputNode, 'awesomplete-selectcomplete', e =>
            this.handleAwesompleteSelectComplete(e));
        this.addListener(this.inputNode, 'awesomplete-open', e => this.handleAwesompleteOpen(e));
        this.addListener(this.inputNode, 'awesomplete-close', e => this.handleAwesompleteClose(e));
        this.addListener(this.inputNode, 'input', e => this.handleInput(e));
        this.addListener(this.el, 'submit', e => this.handleSubmit(e));
        this.addListener(this.inputNode, 'input', this.onChange);
        eventBus.addListener(EVENT_REMOVED_REPLICATOR_ITEM, this.removeSelectedItem);
    };

    onChange = () => {
        if (this.lastSelection !== null) {
            handler.removeItem(this.lastSelection);
            this.lastSelection = null;
        }

        eventBus.emit(EVENT_AUTOCOMPLETE_CHANGED, this.inputNode);
    };

    removeSelectedItem = (container) => {
        if (container.contains(this.refs.input) && (this.refs.input.value !== '')) {
            handler.removeItem(this.refs.input.value);
        }
    };

    init = () => {
        this.setVariables();
        this.addListeners();
        if (this.autoCompleteEnabled) {
            this.createAutocompleteInstanse();
        }
    };


    get defaultSettings() {
        return {
            mainClass: 'autocomplete',
            listClass: 'autocomplete__list'
        };
    }

    get awesompleteSettings() {
        return {
            minChars: this.minChairs,
            maxItems: this.maxItems,
            filter: () => true,
            sort: false,
            item: (text, input) => {
                const inputRegexPattern = Awesomplete.$.regExpEscape(input.trim());
                // highlight only if word starts with pattern
                const regExp = RegExp(`^${inputRegexPattern}|[\\s]+${inputRegexPattern}`, 'gi');
                const html = input.trim() === '' ? text : text.replace(regExp, '<mark>$&</mark>');
                return Awesomplete.$.create('li', {
                    innerHTML: html,
                    'aria-selected': 'false'
                });
            },
            data: (item) => {
                const suggestionObj = {
                    label: item.name,
                    value: item.name
                };
                return suggestionObj;
            }
        };
    }

    createAutocompleteInstanse() {
        this.autoCompleteInstanse = new Awesomplete(this.inputNode, {
            ...this.awesompleteSettings,
            ...this.settings
        });

        this.autoCompleteInstanse.container.className += ` ${this.settings.mainClass}`;
        this.autoCompleteInstanse.ul.classList.add(this.settings.listClass);

        this.listWrap = Awesomplete.$.create('div', {
            hidden: 'hidden',
            className: 'autocomplete__list-wrap',
            around: this.autoCompleteInstanse.ul
        });

        this.spinner = new ElementSpinner(this.autoCompleteInstanse.container);
    }

    setDataFromModel() {
        const list = [...this.jsonData];
        if (this.autoCompleteEnabled) {
            this.autoCompleteInstanse.list = list;
        }
    }

    handleSubmit(e) {
        if (this.inputNode.value.length < this.minChairs) {
            e.preventDefault();
        }
    }

    handleInput = () => {
        if (this.inputNode.value.length < this.minChairs || !this.autoCompleteEnabled) {
            return;
        }

        if (this.onKeyUpTimeOut) {
            clearTimeout(this.onKeyUpTimeOut);
        }
        eventBus.emit(EVENT_AUTOCOMPLETE_SHOWED, this.element);
        this.showSpinner();

        const searchQuery = this.inputNode.value;

        const url = `${this.endPoint}?term=${searchQuery}`;

        this.onKeyUpTimeOut = setTimeout(() => {
            api.get(url).then((response) => {
                this.jsonData = handler.getList(response.data);
                this.setDataFromModel();
                this.hideSpinner();
            });
        }, this.loadingTime);
    };

    handleInputFill(e) {
        if (e.target.value.trim() !== '') {
            this.element.classList.add('is-filled');
        } else {
            this.element.classList.remove('is-filled');
        }
    }

    handleInputFocus() {
        this.element.classList.add('is-focused');
    }

    handleInputBlur() {
        eventBus.emit(EVENT_AUTOCOMPLETE_BLURED, this.element);
        this.element.classList.remove('is-focused');
    }

    resetInputValue() {
        this.inputNode.value = '';
        this.element.classList.remove('is-focused');
        this.element.classList.remove('is-filled');
    }

    handleAwesompleteSelectComplete = (e) => {
        handler.selectedItems.push(e.text.value);
        eventBus.emit(EVENT_AUTOCOMPLETE_SELECTED, this.element);
        this.lastSelection = e.text.value;
        this.isValueSelected = true;
        const id = this.autoCompleteInstanse._list.find(x => x.name === e.text.label).id;
        this.hiddenInputNode.value = id;
    };

    handleAwesompleteOpen(e) {
        this.listWrap.removeAttribute('hidden');
    }

    handleAwesompleteClose(e) {
        this.listWrap.setAttribute('hidden', '');
    }

    showSpinner = () => {
        this.spinner.show();
    };

    hideSpinner = () => {
        this.spinner.hide();
    };

    onDestroy() {
        this.autoCompleteInstanse.destroy();
    }
}
