import BaseComponent from '../../../general/js/base-component';

export default class StoreDetails extends BaseComponent {
    observer = null;

    constructor(...args) {
        super(...args);
        this.init();
    }

    get storeDropdownId() {
        return this.options.storeDropdownId;
    }

    init() {
        if (!this.storeDropdownId) {
            return;
        }

        const storeDropdown$ = document.getElementById(this.storeDropdownId);
        if (!storeDropdown$) {
            // Can't find the store dropdown
            return;
        }

        this.observer = new MutationObserver(() => {
            const data = storeDropdown$.querySelector('[data-passthrough]');
            if (!data) {
                return;
            }

            const storeIdAttribute = data.attributes.getNamedItem('data-passthrough');
            if (!storeIdAttribute) {
                return;
            }

            const newlySelectedStoreId = storeIdAttribute.value;
            if (!newlySelectedStoreId) {
                return;
            }

            Object.entries(this.refs).forEach(([storeId, store$]) => {
                store$.hidden = storeId !== newlySelectedStoreId;
            });
        });

        this.observer.observe(storeDropdown$, {
            childList: true,
            subtree: true,
        });
    }

    onDestroy = () => {
        if (this.observer) {
            this.observer.disconnect();
        }
    }
}
