/**
 * DEFINE_VIEWPORT_WIDTH_DESKTOP_WIDE,
 * DEFINE_VIEWPORT_WIDTH_DESKTOP,
 * DEFINE_VIEWPORT_WIDTH_TABLET,
 * DEFINE_VIEWPORT_WIDTH_MOBILE_WIDE
 * DEFINE_CONTAINER_MAX_WIDTH
 * are filled via webpack.DefinePlugin
 * @type {*[]}
 */

const CONTAINER_MAX_WIDTH = DEFINE_CONTAINER_MAX_WIDTH;
const VIEWPORT_WIDTH_DESKTOP_WIDE = DEFINE_VIEWPORT_WIDTH_DESKTOP_WIDE;
const VIEWPORT_WIDTH_DESKTOP = DEFINE_VIEWPORT_WIDTH_DESKTOP;
const VIEWPORT_WIDTH_TABLET = DEFINE_VIEWPORT_WIDTH_TABLET;
const VIEWPORT_WIDTH_MOBILE_WIDE = DEFINE_VIEWPORT_WIDTH_MOBILE_WIDE;

const EVENT_FORM_REVALIDATE = 'form-revalidate';

export default {
    CONTAINER_MAX_WIDTH,
    VIEWPORT_WIDTH_DESKTOP_WIDE,
    VIEWPORT_WIDTH_DESKTOP,
    VIEWPORT_WIDTH_TABLET,
    VIEWPORT_WIDTH_MOBILE_WIDE,
    EVENT_FORM_REVALIDATE
};
