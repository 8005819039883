class GTM {
    _send(event, data) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event, ...data });
    }

    sendEvent(category, action, label, value) {
        this._send('eventTrack', {
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
            eventValue: value
        });
    }
}

export default new GTM();
