import anime from 'animejs';
import Color from 'color';
import device from 'general/js/device';
import BaseComponent from '../../../general/js/base-component';

export default class ButtonEffect extends BaseComponent {
    constructor(...args) {
        super(...args);

        this.btnWidth = null;
        this.ripple = null;
        this.rippleColor = '';
        this.buttonColor = '';
        this.init();
    }

    init() {
        if (device.isTouch) return;

        this.element.addEventListener('mouseenter', this.displayEffect);
        this.element.addEventListener('mouseleave', this.hideEffect);
    }

    displayEffect = (event) => {
        const x = event.offsetX;
        const y = event.offsetY;

        this.btnWidth = this.btnWidth || this.element.offsetWidth;
        this.rippleColor = this.getRippleColor();

        const w = this.btnWidth * 2;

        const ripple = document.createElement('div');
        ripple.className = 'ripple';
        this.element.appendChild(ripple);

        ripple.style.left = `${x}px`;
        ripple.style.top = `${y}px`;
        ripple.style.backgroundColor = this.rippleColor;

        anime({
            targets: ripple,
            opacity: 1,
            duration: 3000,
            width: w,
            height: w
        });

        this.ripple = ripple;
    };

    hideEffect = () => {
        if (!this.ripple) return;

        const oldRipple = this.ripple;

        anime({
            targets: oldRipple,
            opacity: 0,
            duration: 1500,
            complete: () => {
                this.element.removeChild(oldRipple);
            }
        });

        this.ripple = null;
    };

    getRippleColor() {
        this.buttonColor = window
            .getComputedStyle(this.element)
            .getPropertyValue('background-color');
        let rippleColor = '';

        rippleColor = Color(this.buttonColor)
            .darken(0.1)
            .rgb()
            .string();

        return rippleColor;
    }
}
