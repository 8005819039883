import tippy from 'tippy.js';
import BaseComponent from '../../../general/js/base-component';
import HtmlHelper from '../../../general/js/html-helper';

export default class Tooltip extends BaseComponent {
    constructor(...args) {
        super(...args);

        this.tooltip = null;
        this.content = this.element.dataset.tooltipContent;
        this.isShown = false;
        this.isFirstInList = Object.prototype.hasOwnProperty.call(this.options, 'isFirstInList') ? this.options.isFirstInList : true;

        this.init();
    }

    init() {
        this.tooltip = tippy(this.element, {
            delay: 100,
            arrow: true,
            arrowType: 'sharp',
            size: 'small',
            duration: 250,
            animation: 'scale',
            maxWidth: this.options.maxWidth || '180px',
            placement: this.options.placement || 'right',
            interactive: 'true',
            theme: 'light-border',
            distance: 12,
            content: this.content,
            trigger: 'click',
            onShown: this._onShown,
            onHidden: this._onHidden
        });

        this.triggerElement = HtmlHelper.getParent(this.element, '[data-tooltip-parent]');

        if (this.options.autoStart && this.isFirstInList) {
            this.tooltip.show();
        }

        if (this.triggerElement) {
            this.addListener(this.triggerElement, 'click', this._toggleTooltip);
        }
    }

    _onHidden = () => {
        this.isShown = false;
    };

    _onShown = () => {
        this.isShown = true;
    };

    _toggleTooltip = () => {
        if (this.isShown) {
            this.tooltip.hide();
        } else {
            this.tooltip.show();
        }
    };
}
