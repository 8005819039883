import BaseComponent from '../../../general/js/base-component';

export default class ModalButton extends BaseComponent {
    constructor(...args) {
        super(...args);
        this.init();
    }

    get buttonOpen$() {
        return this.refs['open-button'];
    }

    get buttonClose$() {
        return this.refs['close-button'];
    }

    get modalContent$() {
        return this.refs.content;
    }

    init = () => {
        this.addEventListeners();
    };

    onDestroy = () => {
        this.removeEventListeners();
    }

    addEventListeners = () => {
        if (this.buttonOpen$) {
            this.buttonOpen$.addEventListener('click', this._handleOpenButtonClick);
        }

        if (this.buttonClose$) {
            this.buttonClose$.addEventListener('click', this._handleCloseButtonClick);
        }
    }

    removeEventListeners = () => {
        if (this.buttonOpen$) {
            this.buttonOpen$.removeEventListener('click', this._handleOpenButtonClick);
        }

        if (this.buttonClose$) {
            this.buttonClose$.removeEventListener('click', this._handleCloseButtonClick);
        }
    }

    _handleOpenButtonClick = () => {
        this.modalContent$.hidden = false;
    }

    _handleCloseButtonClick = () => {
        this.modalContent$.hidden = true;
    }
}
