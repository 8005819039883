import fancybox from '@fancyapps/fancybox';
import BaseComponent from '../../../general/js/base-component';

const signalR = require('@aspnet/signalr');

export default class GetArtwork extends BaseComponent {
    constructor(...args) {
        super(...args);

        this.errorMessage = this.refs['error-message'];
        this.buttonsContainer = this.refs['download-buttons-container'];
        this.previewContainer = this.refs['preview-artwork-container'];
        this.previewLink = this.refs['preview-artwork-link'];

        this.init();
    }

    setVariables() {
        this.hash = this.element.getAttribute('data-hash');
    }

    _onDataReceived(data) {
        let model = null;
        try {
            model = JSON.parse(data);
        } catch (e) {
            console.log('Can\'t parse SignalR response:', data);
        }

        this.showErrorMessage(model);
    }

    init() {
        this.setVariables();

        if (this.options.error) {
            this.showErrorMessage(this.options.error);
        }

        this.connection = new signalR.HubConnectionBuilder()
            .withUrl('/signalr')
            .build();

        this.connection.on('send', data => this._onDataReceived(data));

        this.connection.start()
            .then(() => {
                this.connection.invoke('JoinGroup', this.hash);
            });

        $(this.previewLink).fancybox({
            baseClass: 'preview-container',
            infobar: false,
            buttons: false,
            thumbs: false,
            margin: 0,
            baseTpl:
            '<div class="fancybox-container preview__container" role="dialog">' +
            '<div class="fancybox-bg"></div>' +
            '<div class="preview__content">' +
            '<div class="fancybox-stage preview__stage"></div>' +
            '<button data-fancybox-close class="btn-secondary preview__close">Close</button>' +
            '<button data-fancybox-zoom class="preview__zoom">' +
            '<svg class="icon icon-zoomin" width="16" height="16" focusable="false"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-zoomin"></use></svg>' +
            '<svg class="icon icon-zoomout" width="16" height="16" focusable="false"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-zoomout"></use></svg>' +
            '</button>' +
            '</div>' +
            '</div>'
        });
    }

    showErrorMessage(model) {
        if (model !== null) {
            if (model.message !== undefined) {
                this.errorMessage.innerHTML = model.message;
                this.errorMessage.classList.add('error-message');

                this.buttonsContainer.classList.remove('is-loading');
                this.buttonsContainer.classList.add('error');
                this.previewLink.classList.remove('is-loading');
                this.previewLink.classList.add('error');

                const buttons = [...this.buttonsContainer.getElementsByTagName('a')];
                if (buttons.length > 0) {
                    buttons.forEach(button => {
                        button.removeAttribute('href');
                        button.classList.add('error');
                    });
                }

                return;
            }
            // Created = 0,
            // InProgress = 1,
            // Complete = 2,
            // Error = 3
            if (model.status === 2) {
                this.buttonsContainer.classList.remove('is-loading');
                this.previewContainer.classList.remove('is-disabled');
                this.previewLink.classList.remove('is-loading');
            }
        }
    }
}
