import ComponentConfig from './component-config';

export default class ComponentsFactory {
    static getInstance() {
        if (ComponentsFactory.instance === null) {
            ComponentsFactory.instance = new ComponentsFactory();
        }
        return ComponentsFactory.instance;
    }

    constructor() {
        if (ComponentsFactory.instance !== null) {
            return ComponentsFactory.instance;
        }

        this.registeredConfigs = [];
        this.instances = [];
    }

    define(id, className) {
        const componentConfig = new ComponentConfig(id, className);
        this.registeredConfigs.push(componentConfig);
    }

    init(root = null, lazy = false) {
        let result = [];
        this.registeredConfigs.forEach((config) => {
            // we are able to create the same component on the same node multiple times
            // and we don't want to change existing ones or add repetitive to this.instances
            const createdInstances = this._bootstrapConfig(config, root, lazy);
            const newInstances = createdInstances.reduce((result, instance) => {
                if (this.instances.indexOf(instance) === -1) {
                    result.push(instance);
                }
                return result;
            }, []);

            if (newInstances.length > 0) {
                this.instances = [...this.instances, ...newInstances];
            }

            result = [...result, ...createdInstances];
        });

        return result;
    }

    createComponents(root, id, className) {
        const config = new ComponentConfig(id, className);
        return this._bootstrapConfig(config, root);
    }

    createComponent(root, id, className) {
        const components = this.createComponents(root, id, className);
        return components.length > 0 ? components[0] : null;
    }

    destroy(root) {
        this.instances = this.instances.filter((instance) => {
            if (instance.element === root || root.contains(instance.element)) {
                this._destroyComponent(instance);
                return false;
            }

            return true;
        });
    }

    _bootstrapConfig(componentConfig, root = null, lazy = false) {
        if (root === null) {
            root = document.body;
        }
        const className = componentConfig.className;

        let selector = `[${ComponentConfig.getComponentProperty()}="${componentConfig.id}"]`;
        if (!lazy) {
            selector += `:not([${ComponentConfig.getComponentLazyProperty()}])`;
        }

        const elements = [...root.querySelectorAll(selector)];

        const instances = [];

        elements.forEach((element) => {
            if (!element.components) {
                element.components = {};
            }

            if (element.components[componentConfig.id]) {
                instances.push(element.components[componentConfig.id]);
            } else {
                try {
                    const instance = new className(element, componentConfig.id);
                    element.components[componentConfig.id] = instance;
                    instances.push(instance);
                } catch (error) {
                    console.error(
                        `Component ${className.name} with id "${
                            componentConfig.id
                        }" was not created due to error: ${error.message}`,
                        element
                    );
                    console.error(error);
                }
            }
        });

        return instances;
    }

    _destroyComponent(component) {
        component.element.components[component.id] = null;
        component.destroy();
    }
}

ComponentsFactory.instance = null;
