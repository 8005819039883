import axios from 'axios';

export default class CancelingProxy {
    constructor(axiosInstance) {
        this._axiosInstance = axiosInstance;
        this.lastRequestCancel = null;
    }

    _handleBeforeRequest() {
        if (this.lastRequestCancel) {
            this.lastRequestCancel('Request was canceled');
            this.lastRequestCancel = null;
        }
    }

    _getPatchedConfig(config) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        this.lastRequestCancel = source.cancel.bind(source);

        config.cancelToken = source.token;
        return config;
    }

    get(url, config = {}) {
        this._handleBeforeRequest();
        const newConfig = this._getPatchedConfig(config);
        return this._getWrappedResponse(this._axiosInstance.get(url, newConfig));
    }

    post(url, data = {}, config = {}) {
        this._handleBeforeRequest();
        const newConfig = this._getPatchedConfig(config);
        return this._getWrappedResponse(this._axiosInstance.post(url, data, newConfig));
    }

    _getWrappedResponse(requestPromise) {
        return requestPromise.then((response) => {
            this.lastRequestCancel = null;
            return response;
        }, (error) => {
            if (!axios.isCancel(error)) {
                this.lastRequestCancel = null;
            }
            return Promise.reject(error);
        });
    }
}
