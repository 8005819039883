import BaseComponent from '../../../general/js/base-component';

export default class Download extends BaseComponent {
    constructor(...args) {
        super(...args);
        this.init();
    }

    init() {
        this.downloadCopy = this.refs['download-copy'].querySelector('a');
        this.downloadAssets = this.refs['download-assets'].querySelector('a');

        this.onDownloadCopyClickHandler = this.onDownloadCopyClick.bind(this);

        this.downloadCopy.addEventListener('click', this.onDownloadCopyClickHandler);
    }

    onDownloadCopyClick = () => {
        this.downloadAssets.classList.remove('is-disabled');
        this.downloadAssets.classList.add('is-enabled');
        this.downloadCopy.removeEventListener('click', this.onDownloadCopyClickHandler);
    };
}
