class SelectHandler {
    constructor() {

        this.selectedItems= [];
    }

    getList = (data) => {
        let result = [];
        for (let i = 0; i < data.length; i++) {
            let founded = false;
            for (let j = 0; j < this.selectedItems.length; j++) {
                if (data[i].name === this.selectedItems[j]) {
                    founded = true;
                    break;
                }
            }
            if (!founded) {
                result.push(data[i])
            }
        }
        return result;
    };

    removeItem = (value) => {
        for (let i = 0; i < this.selectedItems.length; i++) {
            if (this.selectedItems[i] === value) {
                this.selectedItems.splice(i, 1);
                break;
            }
        }
    };

}

export const handler = new SelectHandler();
