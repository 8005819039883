import flatpickr from 'flatpickr';

import BaseComponent from '../../../general/js/base-component';

export default class FormDateInput extends BaseComponent {
    constructor(...args) {
        super(...args);
        this.instance = null;
        this.disabledDays = this.options.disabledDays;
        this.init();
    }

    init() {
        this._createInstance();
    }

    _createInstance() {
        const options = {
            dateFormat: 'd-m-Y',
            altInput: false,
            altFormat: 'd / m / Y',
            static: true,
            minDate: 'today',
            disable: [
                function (date) {
                    // return true to disable
                    return (date - Date.now() < ((this.disabledDays - 1) * 24 * 60 * 60 * 1000));
                }.bind(this)
            ]
        };

        const date = Date.parse(this.element.getAttribute('value'));
        if (!isNaN(date) && (date !== undefined)) {
            options.defaultDate = new Date(date);
        }

        this.instance = flatpickr(this.element, options);
    }
}
