import BaseComponent from '../../../general/js/base-component';
import tippy from 'tippy.js';

export default class Card extends BaseComponent {
    constructor(...args) {
        super(...args);

        this.init();
    }

    init() {
        if (!this.element.classList.contains('card--customizable')) return false;

        this.tooltip = tippy(this.refs.customizableButton, {
            content: this.refs.tooltipContent.innerHTML,
            placement: 'right',
            theme: 'light-green',
            trigger: 'click',
            arrow: true,
            zIndex: 2,
            offset: '60, -30',
            flip: false
        })
    }
}
