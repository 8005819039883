import ComponentsFactory from '../../../general/js/components-factory';
import constants from '../../../general/js/constants';
import eventBus from '../../../general/js/event-bus';
import BaseComponent from '../../../general/js/base-component';
import { EVENT_REMOVED_REPLICATOR_ITEM } from './events';
import {
    EVENT_AUTOCOMPLETE_SELECTED,
    EVENT_AUTOCOMPLETE_CHANGED,
    EVENT_AUTOCOMPLETE_SHOWED,
    EVENT_AUTOCOMPLETE_BLURED
} from '../../autocomplete/js/events';
import { EVENT_INPUT_CLEANED, EVENT_INPUT_FILLED } from '../../form-input/js/events';

export default class Replicator extends BaseComponent {
    constructor(...args) {
        super(...args);

        this.appendOrder = this.element.getAttribute('data-append-order') || 'beforeEnd';
        this.container = this.refs.container;
        this.addButton = this.refs.button;
        this.templateId = this.element.getAttribute('data-template');
        this.source = document.getElementById(this.templateId).innerHTML;

        this.init();
    }

    init() {
        this.checkOnAutoFilled();
        this.addListeners();
    }

    addListeners() {
        super.addListener(this.addButton, 'click', this._onAddButtonClick);
        this.addListener(this.element, 'click', this._onRemoveButtonClick);
        eventBus.addListener(EVENT_AUTOCOMPLETE_SELECTED, this.unBlockAddButton);
        eventBus.addListener(EVENT_AUTOCOMPLETE_CHANGED, this.blockAddButton);
        eventBus.addListener(EVENT_AUTOCOMPLETE_SHOWED, this.setZindex);
        eventBus.addListener(EVENT_AUTOCOMPLETE_BLURED, this.unsetZindex);
        eventBus.addListener(EVENT_INPUT_FILLED, this.unBlockAddButton);
        eventBus.addListener(EVENT_INPUT_CLEANED, this.blockAddButton);
    }

    setZindex = (element) => {
        const closest = this.getClosestParent(element);
        if (closest) closest.style.zIndex = 5;
    };

    unsetZindex = (element) => {
        const closest = this.getClosestParent(element);
        if (closest) closest.style.zIndex = '';
    };

    getClosestParent = (element) => {
        if (element) {
            return element.closest('[data-cloned-element]');
        } else {
            return false;
        }
    };

    checkOnAutoFilled = () => {
        if (this.refs.defaultInput && (this.refs.defaultInput.value === '')) {
            this.blockAddButton();
        }
    };

    unBlockAddButton = (element) => {
        this.unsetZindex(element);
        this.addButton.removeAttribute('disabled');
    };

    blockAddButton = () => {
        this.addButton.setAttribute('disabled', 'true');
    };

    async _initComponents(lastEntry) {
        await ComponentsFactory.getInstance().init(lastEntry);

        setTimeout(() => {
            const firstInput = lastEntry.querySelector('input[type=text]:first-child');
            if (firstInput) {
                firstInput.focus();
            }
        }, 0);
    }

    async _destroyComponents(container) {
        await ComponentsFactory.getInstance().destroy(container);
    }

    _onAddButtonClick = () => {
        let index = 0;
        const node = this.container.querySelector('[data-cloned-element-index]:last-of-type');
        if (node != null) {
            index = Number(node.getAttribute('data-cloned-element-index')) + 1;
        }
        const wrappedElement = this.source.replace(/{index}/gi, index);
        this.container.insertAdjacentHTML(this.appendOrder, wrappedElement);
        const entryList = this.container.querySelectorAll('[data-cloned-element]');
        const lastEntry = entryList[entryList.length-1];
        this._initComponents(lastEntry);
        this.blockAddButton();
        eventBus.emit(constants.EVENT_FORM_REVALIDATE);

        setTimeout(()=> {
            lastEntry.classList.remove('show');
        }, 310);
    };

    _onRemoveButtonClick = (e) => {
        if (e.target && e.target.matches('[data-remove-button]')) {
            const container = e.target.closest('[data-cloned-element]');
            eventBus.emit(EVENT_REMOVED_REPLICATOR_ITEM, container);
            container.classList.add('hide');
            this.unBlockAddButton();
            setTimeout(() => {
                this._destroyComponents(container);
                container.remove();
                eventBus.emit(constants.EVENT_FORM_REVALIDATE);
            }, 310);
        }
    };
}
