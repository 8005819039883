// general styles
import './general/scss/index.scss';

// global stuff & polyfils
require('element-closest');
require('object-fit-images')();

import './general/js/font-loader';
import './general/js/lazysizes';
import 'what-input';

// components
import './components/wizard-steps';
import './components/richtext';
import './components/spinner';
import './components/header';
import './components/button-effect';
import './components/tooltip';
import './components/form';
import './components/form-input';
import './components/form-date-input';
import './components/form-input-characters-manager';
import './components/form-select-customise';
import './components/pod';
import './components/wizard';
import './components/autocomplete';
import './components/replicator';
import './components/summary-list';
import './components/card';
import './components/scrollable';
import './components/tag';
import './components/dropdown';
import './components/show-if';
import './components/toggle-area';
import './components/form-range-manager';
import './components/section-header';
import './components/list-with-filter';
import './components/modal-button';
import './components/store-details';
import './components/get-artwork';
import './components/grey-block';
import './components/download';
import './components/appearing-dropdowns';

/* init app */

import './general/js/device';
import app from './general/js/app';

app.init();

/* require svg */
const files = require.context('./general/svg', true, /^\.\/.*\.svg/);
files.keys().forEach(files);

// do not focus sprite in IE
const spriteNode = document.getElementById('__SVG_SPRITE_NODE__');

if (spriteNode) {
    spriteNode.setAttribute('focusable', 'false');
    spriteNode.setAttribute('aria-hidden', 'true');
}
