import './scss/index.scss';
import ComponentsFactory from '../../general/js/components-factory';
import FormComponent from './js/form.component';
import CustomSelect from './js/custom-select.component';

ComponentsFactory.getInstance().define('form', FormComponent);
ComponentsFactory.getInstance().define('custom-select', CustomSelect);

const files = require.context('./svg', true, /^\.\/.*\.svg/);
files.keys().forEach(files);
